import React, { useMemo } from "react";
import tvgConf from "@tvg/conf";
import {
  Paragraph,
  Header,
  Checkbox,
  useColorTokens,
  Loading,
  Switch,
  Icon,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import {
  Container,
  TogglesContainer,
  SwitchWrapper,
  ButtonContainer,
  ToggleContainer,
  ContentContainer
} from "./styled-components";

export type ToggleGroupElement = {
  name: string;
  onClick: () => void;
  defaultValue?: boolean;
  qaLabel: string;
  btnType?: boolean;
  url?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

interface ToggleGroupProps {
  title: string;
  description?: string;
  toggles: Array<ToggleGroupElement>;
  qaLabel: string;
}

const ToggleGroup = ({
  qaLabel: toggleGroupQaLabel,
  title,
  description,
  toggles
}: ToggleGroupProps) => {
  const colorTokens = useColorTokens();
  const isMobileDevice = tvgConf().device === "mobile";
  // Since the media query breakpoint is under cookie, there is a need to check device
  // For places that already are using Preferences
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm) || isMobileDevice;
  const isTablet = tvgConf().device === "tablet";
  const isDesktop = tvgConf().device === "desktop";

  const renderTogglesMobile = useMemo(
    () =>
      toggles.map((item: ToggleGroupElement) =>
        item.btnType ? (
          <ButtonContainer
            to={item.url || ""}
            onClick={item.onClick}
            isTablet={isTablet}
            data-qa-label={`${item.qaLabel}-button`}
            isMobileDevice={isMobileDevice}
          >
            <Paragraph
              qaLabel={`${item.qaLabel}-text`}
              fontSize="s"
              fontFamily="regular"
              color={colorTokens.content.default}
            >
              {item.name}
            </Paragraph>
            <Icon
              name="chevronRight"
              size="s"
              lineColor={colorTokens.content.subtle}
            />
          </ButtonContainer>
        ) : (
          <ToggleContainer
            key={item.name}
            isDesktop={isDesktop}
            isTablet={isTablet}
            isMobileDevice={isMobileDevice}
          >
            <Paragraph qaLabel={`${item.qaLabel}-toggle-name`}>
              {item.name}
            </Paragraph>
            <SwitchWrapper>
              {item.isLoading && (
                <Loading
                  size="s"
                  color={colorTokens.component.input.active}
                  bgColor={colorTokens.component.input.border}
                  qaLabel="switch-spinner"
                />
              )}
              <Switch
                isOn={item.defaultValue || false}
                onPress={item.onClick}
                qaLabel={`${item.qaLabel}-toggle-${item.defaultValue}`}
                isDisabled={item.isDisabled || false}
              />
            </SwitchWrapper>
          </ToggleContainer>
        )
      ),
    [toggles]
  );

  const renderTogglesDesktop = useMemo(
    () =>
      toggles.map((item: ToggleGroupElement, index) => (
        <ToggleContainer
          key={item.name}
          isMobileDevice={isMobileDevice}
          isDesktop
        >
          <Checkbox.Group
            isDisabled={item.isLoading || item.isDisabled}
            selectedValues={item.defaultValue ? [item.qaLabel] : []}
            onChange={item.onClick}
            qaLabel={`${item.qaLabel}-${item.defaultValue}-group`}
            mt={index > 0 ? "space-4" : 0}
          >
            <Checkbox
              value={item.qaLabel}
              label={item.name}
              qaLabel={`${item.qaLabel}-${item.defaultValue}-checkbox`}
              marginBottom={0}
            />
          </Checkbox.Group>
        </ToggleContainer>
      )),
    [toggles]
  );

  return (
    <Container
      data-qa-label={toggleGroupQaLabel}
      isMobileDevice={isMobileDevice}
    >
      <ContentContainer isMobileDevice={isMobileDevice}>
        <Header tag="h3" qaLabel={`${toggleGroupQaLabel}-title`}>
          {title}
        </Header>
        {description && (
          <Paragraph
            color={colorTokens.content.subtle}
            qaLabel={`${toggleGroupQaLabel}-description`}
          >
            {description}
          </Paragraph>
        )}
      </ContentContainer>
      <TogglesContainer isMobileDevice={isMobileDevice}>
        {isMobile || isTablet ? renderTogglesMobile : renderTogglesDesktop}
      </TogglesContainer>
    </Container>
  );
};

export default ToggleGroup;
