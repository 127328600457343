import { get } from "lodash";
import { Store } from "redux";
import { WagerProfile, UserOptedInPromos } from "@tvg/ts-types/User";
import { SeoTrack } from "@tvg/ts-types/Track";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import {
  OptInPromotion,
  Promo,
  UserEligiblePromotion
} from "@tvg/ts-types/Promos";

export const getIsLogged = (store: Store): boolean =>
  get(store, "userData.logged", false);

export const getAccountNumber = (store: Store): string =>
  get(store, "userData.user.accountNumber", "");

export const getFdUserId = (store: Store): string =>
  get(store, "userData.user.fdUserId", "");

export const getWagerProfile = (store: Store): WagerProfile =>
  get(store, "userData.user.profile") || getPortByBrand();

export const getTracksData = (store: Store): Array<SeoTrack> =>
  get(store, "app.seoTrackList", []);

export const getOptedInPromos = (store: Store): UserOptedInPromos =>
  get(store, "userData.optedInPromos", {});

export const getBalance = (store: Store): number =>
  get(store, "userData.balance", 0);

export const getBalancePreference = (store: Store): string =>
  get(store, "userData.preferences.balance_is_shown", "0");

export const getResidenceState = (store: Store): string =>
  get(store, "userData.user.homeAddress.state", "");

export const getFirstName = (store: Store): string =>
  get(store, "userData.user.firstName", "");

export const getLastName = (store: Store): string =>
  get(store, "userData.user.lastName", "");

export const getIsVerified = (store: Store): boolean =>
  get(store, "userData.isVerified", false);

export const getIsTermsAccepted = (store: Store): boolean =>
  get(store, "userData.jurisdiction.accepted", false);

export const getEmail = (store: Store): string =>
  get(store, "userData.user.emailAddress");

export const getUserPromotions = (store: Store): Promo[] =>
  get(store, "userPromos.promotions", []);

export const getUserEligiblePromotions = (
  store: Store
): UserEligiblePromotion[] => get(store, "userPromos.eligiblePromotions", []);

export const getUserOptedInPromotions = (store: Store): OptInPromotion[] =>
  get(store, "userPromos.optedInPromotions", []);
