import { get } from "lodash";
import { Store } from "redux";
import parseCAPIMessage from "@tvg/utils/capiUtils";

import {
  AccountPrefsMessage,
  BettingCapi,
  ConfirmationModal,
  ConfirmModalMessage,
  DefaultBetPrefs,
  NotificationsCapi,
  TalentAlertsCapi,
  PreferencesTabEnum,
  RaceMtpOptions,
  ToggleStateEnum,
  EmailUnsubscribeMessage,
  EmailSubscribeFdrMessage,
  EmailUnsubscribeFdrMessage,
  AlertPrefs,
  AlertsCapi,
  NotificationsSwitchState
} from "../utils/types";

export const getWagerPadToggle = (store: Store) =>
  get(store, "capi.featureToggles.enableURPWagerPad", false);

export const getPreferencesToggle = (store: Store) =>
  get(store, "capi.featureToggles.usePrefReact", false);

export const getNotificationPreferencesToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.notificationPreferences", false);

export const getEmailUnsubscribeToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.useEmailUnsubscribe", false);

export const getShowMuteAllNotificationsPreferences = (store: Store): boolean =>
  get(store, "capi.featureToggles.showMuteAllNotificationsPreferences", false);

export const getShowMuteAllNotificationsPreferencesBeta = (
  store: Store
): boolean =>
  get(
    store,
    "capi.featureToggles.showMuteAllNotificationsPreferences-beta",
    false
  );

export const getBalanceIsShown = (store: Store): ToggleStateEnum =>
  get(store, "userData.preferences.balance_is_shown", ToggleStateEnum.OFF);

export const getShowBetConfirm = (store: Store): string =>
  get(store, "userData.preferences.show_bet_confirm", ToggleStateEnum.OFF);

export const getLegacyBetType = (store: Store): string =>
  get(store, "userData.preferences.bet_type", "");

export const getBetPrefs = (store: Store): DefaultBetPrefs =>
  get(store, "userData.preferences.bet_prefs", {});

export const getUserBetTypePref = (store: Store): string =>
  get(store, "userData.preferences.bet_prefs.defaultBetType", "");

export const getUserBetAmountPref = (store: Store): { [key: string]: number } =>
  get(store, "userData.preferences.bet_prefs.default_bet_amount", {});

export const getUseMinimalBetAmount = (store: Store): boolean =>
  get(store, "userData.preferences.bet_prefs.useMinimumBetAmount", true);

export const getBettingPrefsCenterMessages = (store: Store): BettingCapi =>
  get(parseCAPIMessage(store, "capi.messages.preferencesCenter"), "betting", {
    betConfirmation: {
      title: "Bet confirmation",
      description: "Double-check your bet before you place it.",
      label: "Request bet confirmation"
    },
    betType: {
      title: "Default bet type",
      description: "Set your first choice for the Program Page and Watch FDTV.",
      descriptionWagerPad:
        "Set your first choice for the Program Page, Watch FDTV and Wagerpad.",
      descriptionMobile:
        "Set your first choice for the Program Page and Watch FDTV."
    },
    betAmount: {
      title: "Default bet amount",
      label: [
        "Use minimum amount for each bet type",
        "Custom amounts for each bet type"
      ],
      message:
        "The minimum bet amount will be set if your chosen amount is unavailable",
      placeholder: "Minimum amount"
    },
    resetButton: "Reset All Betting Preferences"
  });

export const getNotificationsPrefsCenterMessages = (
  store: Store
): NotificationsCapi =>
  get(
    parseCAPIMessage(store, "capi.messages.preferencesCenter"),
    "notifications",
    {
      raceAlerts: {
        title: "",
        description: "",
        label: ["", "", ""],
        footer: ["", ""]
      },
      offersNews: {
        title: "",
        description: "",
        label: ["", ""]
      },
      promotionActivity: {
        title: "",
        description: "",
        label: ["", ""]
      },
      talentPicks: {
        title: "",
        description: "",
        label: [""],
        btnText: ""
      },
      other: {
        title: "",
        description: "",
        label: ["", ""]
      },
      muteBtn: {
        mute: "",
        unmute: ""
      },
      alerts: {
        error: {
          title: "",
          message: ""
        }
      }
    }
  );

export const getAlertsPrefsCenterMessages = (store: Store): AlertsCapi =>
  get(parseCAPIMessage(store, "capi.messages.preferencesCenter"), "alerts", {
    error: {
      title: "",
      message: "",
      hasButton: ""
    },
    "error-single": {
      title: "",
      message: "",
      hasButton: ""
    },
    success: {
      title: "",
      message: "",
      hasButton: ""
    }
  });

export const getTalentAlertsMessages = (store: Store): TalentAlertsCapi =>
  get(
    parseCAPIMessage(store, "capi.messages.preferencesCenter"),
    "talentAlerts",
    { headerMessage: "" }
  );

export const getEmailUnsubscribeMessage = (
  store: Store
): EmailUnsubscribeMessage =>
  parseCAPIMessage(store, "capi.messages.emailUnsubscribePage", {
    unsubscribe: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: "",
      buttonTextSecondary: ""
    },
    fallback: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: "",
      buttonTextSecondary: "",
      secondaryUrl: ""
    },
    confirmation: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      label: "",
      buttonTextPrimary: "",
      buttonTextSecondary: ""
    },
    error: {
      name: "",
      title: "",
      label: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: "",
      buttonTextSecondary: ""
    }
  });

export const getEmailUnsubscribeFdrMessage = (
  store: Store
): EmailUnsubscribeFdrMessage =>
  parseCAPIMessage(store, "capi.messages.emailUnsubscribePageFdr", {
    unsubscribe: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: ""
    },
    confirmation: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      label: "",
      buttonTextPrimary: ""
    },
    error: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: "",
      buttonTextSecondary: "",
      secondaryUrl: ""
    },
    fallback: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: "",
      buttonTextSecondary: "",
      secondaryUrl: ""
    },
    subscriptionGroups: {
      email_offers_news: "",
      email_promotion_activity: "",
      email_fanduel_comms: ""
    }
  });

export const getEmailSubscribeFdrMessage = (
  store: Store
): EmailSubscribeFdrMessage =>
  parseCAPIMessage(store, "capi.messages.emailSubscribePageFdr", {
    subscribe: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: ""
    },
    confirmation: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      label: "",
      buttonTextPrimary: ""
    },
    error: {
      name: "",
      title: "",
      bodyContent: [{ description: "" }],
      buttonTextPrimary: ""
    }
  });

export const getConfirmationModalPrefsCenterMessages = (
  store: Store
): ConfirmModalMessage =>
  get(
    parseCAPIMessage(store, "capi.messages.preferencesCenter"),
    "confirmModal",
    {
      BETTING: {
        title: "",
        firstParagraph: "",
        btnText: ""
      },
      NOTIFICATIONS: {
        title: "",
        firstParagraph: "",
        secondParagraph: "",
        btnText: "",
        secondBtnText: ""
      }
    }
  );

export const getSelectedTab = (store: Store): PreferencesTabEnum =>
  get(store, "preferences.selectedTab");

export const getNotificationsSwitches = (
  store: Store
): NotificationsSwitchState => get(store, "preferences.notifications");

export const getConfirmationModalInfo = (store: Store): ConfirmationModal =>
  get(store, "preferences.confirmationModal");

export const getIsPreferencesModalOpen = (store: Store): boolean =>
  get(store, "preferences.isPreferencesModalOpen", false);

export const getAlertPrefs = (store: Store): AlertPrefs =>
  get(store, "preferences.alert", {
    show: false,
    type: "error",
    isToggling: false,
    shouldShowButton: false
  });

export const getMuteAllCommunications = (store: Store): boolean =>
  get(store, "preferences.muteAllCommunications", true);

export const getAccountPrefsCenterMessages = (
  store: Store
): AccountPrefsMessage =>
  parseCAPIMessage(store, "capi.messages.preferencesCenter", {
    account: {
      auth: {
        title: "",
        toggleTitle: ""
      }
    }
  });

export const getBiometricToggleState = (store: Store): boolean =>
  get(store, "ios.touchId.touchIdEnabled", false);

export const getBiometricType = (store: Store): number =>
  get(store, "ios.init.touchIdType", 0);

export const getRaceAlertMtp = (store: Store): RaceMtpOptions =>
  get(store, "userData.preferences.raceAlertMtp", undefined);
